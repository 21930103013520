const AOS = require('aos');
const $ = require('jquery');
// const _ = require('lodash');

require('@popperjs/core');
require('bootstrap');
require('slick-carousel');
require('magnific-popup'); 

$(document).ready(function () {
  loadAOS();

  //Instagram
  var feed = new Instafeed({
    get: "user",
    target: "instafeed",
    accessToken: "2911974135.1677ed0.a8eee61c3edb44dcb1dcc432e4cb5942",
    clientId: "447040641b6d44ffbc4653a6a5562a0e",
    userId: 2911974135,
    limit: 8,
    sortBy: "most-recent",
    resolution: "standard_resolution",
    template:
      '<div class="col-12 col-md-3 d-block mb-8 px-3"><a href="{{link}}" target="_blank"><div class="landscape-background instagram" style="background-image: url({{image}});"><span class="hover"><i class="fab fa-instagram white"></i></span></div></a></div>',
  });
  feed.run();

  var feed = new Instafeed({
    get: "user",
    target: "instafeed-sidebar",
    accessToken: "2911974135.1677ed0.a8eee61c3edb44dcb1dcc432e4cb5942",
    clientId: "447040641b6d44ffbc4653a6a5562a0e",
    userId: 2911974135,
    limit: 4,
    sortBy: "most-recent",
    resolution: "standard_resolution",
    template:
      '<div class="col-6 d-block mb-8 px-3"><a href="{{link}}" target="_blank"><div class="landscape-background instagram sidebar" style="background-image: url({{image}});"><span class="hover"><i class="fab fa-instagram white"></i></span></div></a></div>',
  });
  feed.run();

  // Scroll trigger.
  $(window).on("scroll", function () {
    if ($(window).scrollTop() > 0) {
      $("#navigation").addClass("shadow");
    } else {
      $("#navigation").removeClass("shadow");
    }
  });

  var sticky = $("#navigation");
  var stickyHeight = sticky.outerHeight();
  var currentScrollPosition = $(window).scrollTop();
  var mobile = $(window).width() < 768;

  $("body").css("margin-top", stickyHeight + "px");
  sticky.addClass("fixed");

  $(window).on("load resize", function () {
    mobile = $(window).width() < 768;
    stickyHeight = sticky.outerHeight();
    $("body").css("margin-top", stickyHeight + "px");
    currentScrollPosition = $(window).scrollTop();
    scrollHandler();
  });

  $(window).on("scroll", _.throttle(scrollHandler, 100));
  function scrollHandler() {
    var newScrollPosition = $(window).scrollTop();
    var pastThePointOfNoReturn = newScrollPosition > stickyHeight;
    sticky.toggleClass("scrolled", pastThePointOfNoReturn && !mobile);
    var scrolledUp = newScrollPosition <= currentScrollPosition;
    sticky.toggleClass("show", scrolledUp && pastThePointOfNoReturn && !mobile);
    currentScrollPosition = newScrollPosition;
  }

  // function offsetContent() {
  //   let nav = $("#navigation");
  //   let content = $(".hero.offset");

  //   if (nav.length && content.length) {
  //     let offsetAmount = nav.outerHeight();
  //     content.css("margin-top", `${offsetAmount}px !important;`);
  //   }
  // }
  // offsetContent();

  $(".morelink").click(function () {
    var txt = $(this).prev(".show-text").is(":visible") ? "More" : "Hide";
    $(this).text(txt);
    $(this).prev(".show-text").slideToggle("slow");
  });

  if ($(window).width() <= 992) {
    $(".filters").hide();
    $(".filter").click(function () {
      $(".filters").slideToggle("slow");
      $(this).toggleClass("rotate");
    });
  }

  // Triggering burger menu.
  $(".burger-wrapper").click(function () {
    $("#menu").toggleClass("navbar-active");
  });

  // Adds the active class to the relevant links.
  $(".navbar-nav [href]").each(function () {
    if (this.href == window.location.href) {
      $(this).addClass("active");
    }
  });

  // Creates the hero carousel.
  $(".hero-carousel").slick({
    arrows: false,
    draggable: false,
    fade: true,
    swipe: false,
  });
  $(".hero-bg-carousel").slick({
    arrows: false,
    draggable: false,
    fade: true,
    swipe: false,
  });
  var dots = $(".hero-dots .hero-dot");
  dots.click(function () {
    dots.each(function () {
      $(this).removeClass("active");
    });
    var current = $(this);
    current.addClass("active");
    $(".hero-carousel").slick("slickGoTo", current.data("slide-index"));
    $(".hero-bg-carousel").slick("slickGoTo", current.data("slide-index"));
  });

  // Creates the news carousel.
  $(".news-carousel").slick({
    arrows: true,
    draggable: true,
    nextArrow:
      '<span class="slick-arrow slick-next"><i class="fal fa-chevron-right navy" style="font-weight: 100 !important;"></i></span>',
    prevArrow:
      '<span class="slick-arrow slick-prev"><i class="fal fa-chevron-left navy" style="font-weight: 100 !important;"></i></span>',
    slidesToScroll: 1,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  $(".carousel").slick({
    arrows: true,
    dots: true,
    autoplay: false,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
    slidesToScroll: 1,
    slidesToShow: 1,
    prevArrow: '<button class="slick-arrow slick-prev"></button>',
    nextArrow: '<button class="slick-arrow slick-next"></button>',
  });

  $(".anchors a").click(function (e) {
    event.preventDefault();
    $("html, body").animate(
      { scrollTop: $($(this).attr("href")).offset().top },
      500
    );
  });

  $("#sign-in").click(function () {
    let root = $("html");
    root.addClass("overflow-hidden");

    let target = $("#login-modal");
    if (target.length) {
      let args = {
        backdrop: "static",
      };
      target.modal(args);
    } else {
      root.removeClass("overflow-hidden");
      console.error("Error opening modal with target id of '#login-modal'");
    }
  });

  $("#header-search").click(function () {
    let root = $("html");
    root.addClass("overflow-hidden");

    let target = $("#search-modal");
    if (target.length) {
      let args = {
        backdrop: "static",
      };
      target.modal(args);
    } else {
      root.removeClass("overflow-hidden");
      console.error("Error opening modal with target id of '#search-modal'");
    }
  });

  // general modal close function
  $(".modal-close").click(function () {
    let root = $("html");
    root.removeClass("overflow-hidden");

    let target = $(this).closest(".modal");
    if (target.length) {
      console.log(target);
      target.modal();
    }
  });

  // login modal content stuff
  $("p.login-remember label").click(function (event) {
    event.preventDefault();

    // get the checkbox input element
    let checkbox = $(this).find('input[type="checkbox"]');
    // get the status of the checkbox
    let propValue = checkbox.prop("checked");
    // set the new value to the inverse of the original value
    checkbox.prop("checked", !propValue);
    // set the label element to display the check
    $(this).toggleClass("checked");
  });

  $("#sign-in-tab").click(function (event) {
    event.preventDefault();

    let oldTab = $("#register-tab");
    oldTab.removeClass("active");

    let oldPanel = $("#register-panel");
    oldPanel.addClass("d-none");

    let newPanel = $("#sign-in-panel");
    newPanel.removeClass("d-none");

    $(this).addClass("active");
  });

  $("#register-tab").click(function (event) {
    event.preventDefault();

    let oldTab = $("#sign-in-tab");
    oldTab.removeClass("active");

    let oldPanel = $("#sign-in-panel");
    oldPanel.addClass("d-none");

    let newPanel = $("#register-panel");
    newPanel.removeClass("d-none");

    $(this).addClass("active");
  });

  // Initializing datepicker.
  $(".datepicker").datepicker();
});

/**
 * @description loads the AOS library after a set timeout
 */
 let loadAOS = () => {
  setTimeout(() => {
    $("body").addClass("loaded");
    setTimeout(() => {
      AOS.init();
    }, 500);
  }, 1000);
};

/*
 * ACF Google Map Script
 */

(function ($) {
  /*
   *  new_map
   *
   *  This function will render a Google Map onto the selected jQuery element
   *
   *  @type	function
   *  @date	8/11/2013
   *  @since	4.3.0
   *
   *  @param	$el (jQuery element)
   *  @return	n/a
   */

  function new_map($el) {
    // var
    var $markers = $el.find(".marker");

    // vars
    var args = {
      zoom: 16,
      disableDefaultUI: true,
      center: new google.maps.LatLng(0, 0),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      styles: [
        {
          elementType: "geometry",
          stylers: [
            {
              color: "#f5f5f5",
            },
          ],
        },
        {
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#616161",
            },
          ],
        },
        {
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#f5f5f5",
            },
          ],
        },
        {
          featureType: "administrative.land_parcel",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#bdbdbd",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "geometry",
          stylers: [
            {
              color: "#eeeeee",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [
            {
              color: "#e5e5e5",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [
            {
              color: "#dadada",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#616161",
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e",
            },
          ],
        },
        {
          featureType: "transit.line",
          elementType: "geometry",
          stylers: [
            {
              color: "#e5e5e5",
            },
          ],
        },
        {
          featureType: "transit.station",
          elementType: "geometry",
          stylers: [
            {
              color: "#eeeeee",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              color: "#c9c9c9",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e",
            },
          ],
        },
      ],
    };

    // create map
    var map = new google.maps.Map($el[0], args);

    // add a markers reference
    map.markers = [];

    // add markers
    $markers.each(function () {
      add_marker($(this), map);
    });

    // center map
    center_map(map);

    // return
    return map;
  }

  /*
   *  add_marker
   *
   *  This function will add a marker to the selected Google Map
   *
   *  @type	function
   *  @date	8/11/2013
   *  @since	4.3.0
   *
   *  @param	$marker (jQuery element)
   *  @param	map (Google Map object)
   *  @return	n/a
   */

  function add_marker($marker, map) {
    // var
    var latlng = new google.maps.LatLng(
      $marker.attr("data-lat"),
      $marker.attr("data-lng")
    );

    var icon = {
      // url: '/app/themes/frogspark/img/pointer.svg', // url
      // scaledSize: new google.maps.Size(80, 80), // scaled size
    };

    // create marker
    var marker = new google.maps.Marker({
      position: latlng,
      map: map,
      // icon: icon,
    });

    // add to array
    map.markers.push(marker);

    // if marker contains HTML, add it to an infoWindow
    if ($marker.html()) {
      // create info window
      var infowindow = new google.maps.InfoWindow({
        content: $marker.html(),
      });

      // show info window when marker is clicked
      google.maps.event.addListener(marker, "click", function () {
        infowindow.open(map, marker);
      });

      if (!$marker.attr("data-auto-show")) {
        infowindow.open(map, marker);
      }

      // infowindow.open(map, marker);
    }
  }

  /*
   *  center_map
   *
   *  This function will center the map, showing all markers attached to this map
   *
   *  @type	function
   *  @date	8/11/2013
   *  @since	4.3.0
   *
   *  @param	map (Google Map object)
   *  @return	n/a
   */

  function center_map(map) {
    // vars
    var bounds = new google.maps.LatLngBounds();

    // loop through all markers and create bounds
    $.each(map.markers, function (i, marker) {
      var latlng = new google.maps.LatLng(
        marker.position.lat(),
        marker.position.lng()
      );
      bounds.extend(latlng);
    });

    // only 1 marker?
    if (map.markers.length == 1) {
      // set center of map
      map.setCenter(bounds.getCenter());
      map.setZoom(14);
    } else {
      // fit to bounds
      map.fitBounds(bounds);
    }
  }

  /*
   *  document ready
   *
   *  This function will render each map when the document is ready (page has loaded)
   *
   *  @type	function
   *  @date	8/11/2013
   *  @since	5.0.0
   *
   *  @param	n/a
   *  @return	n/a
   */
  var map = null;
  $(document).ready(function () {
    $(".acf-map").each(function () {
      // Creates the map.
      map = new_map($(this));
    });
  });
})($);
